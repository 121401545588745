import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "about-image-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      coreValues: file(relativePath: { eq: "bodhi-tree-v3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Study Early Childhood Education NZ" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Study Early Childhood Education (ECE)</h1>
      </BackgroundImage>
      <div id="features" className="about__features bodhi__bg-pattern">
        <div className="container">
          <div className="feature_wrapper option2">
            <div className="row">
              <div className="col-md-12 feature feature__bottom-row">
                <p>
                  Bodhi was created by two passionate early childhood educators,
                  Kim & Kerry, who have over 40 years combined experience in the
                  Early Childhood Education (ECE) sector. We have been mothers,
                  educators, team leaders, managers and mentors to hundreds of
                  students during this time.
                </p>

                <p>
                  It is this experience with students and families/whanau within
                  our community that sparked our desire to create revolutionary
                  Early Childhood Education qualifications within Aotearoa New
                  Zealand. We provide training in early childhood education that
                  is authentic to the amazing bicultural and multicultural
                  environment that we live in, where educators teach from the
                  heart and where children are at the heart of the matter…
                  always!!! Our graduates will ensure all children get to play
                  and explore freely and recognise that learning only takes
                  place when it is child initiated and when educators are truly
                  innovative and excited about their roles.
                </p>

                <p>
                  The desire for our courses is to create educators, parents,
                  carers and nannies that have been given diverse knowledge on
                  child development therefore discovering their own true core
                  values. This process of self-discovery will drive confidence
                  and competence in caring, nurturing and educating infants,
                  toddlers and young children.
                </p>

                <p>
                  Our vision is for students to develop a higher level of
                  understanding and respect for newborn, infants and toddlers,
                  which will result in a raised level of care and education for
                  children throughout Aotearoa.
                </p>

                <p>
                  We believe that there is much to be gained by educating
                  students on respectful care based on scientific research
                  around Neuroscience. Students will develop life-long skills
                  which will promote their sense of well-being and esteem.
                  Through the knowledge that students acquire, you will be able
                  to apply the best practices for the optimal care and
                  education.
                </p>

                <p>
                  Bodhi means to awaken, and as our name suggests, we believe in
                  passing on supreme knowledge on infant, toddler and young
                  children care and development, your enlightenment and
                  awareness will be the natural progression that follows. We
                  utilise the Bodhi tree as our logo as it symbolises our 4 core
                  values.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-8 col-md-offset-2 feature feature__bottom-row">
                <div>
                  <Img
                    fluid={data.coreValues.childImageSharp.fluid}
                    alt="Bodhi Core Values"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="text">
                  <p>
                    Bodhi is different to other providers because our main focus
                    is to provide a supportive learning environment, we have a
                    specialised team that is balanced in the vast variety of
                    skills necessary to ensure our learners are feel supported,
                    cared for, listened too while learning all the knowledge
                    necessary. We have a small team of tutors that you will get
                    to know during your time of study with us. Kerry & Kim are
                    Co-Directors as well as tutors and we have Julie Dent our
                    Academic Dean who also primarily focuses on the Level 6 and
                    Mari Allely who takes wonderful care of all our Level 5
                    learners, transitioning with them into this amazing learning
                    journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
